import Axios from '@/axios';
import { flushAllInteractions } from '@/composables/interaction';
import loader from '@/composables/loader';
import { fetchingNotifications } from '@/composables/notifs-center';
import { showNotificationModal, updateNotificationPermission } from '@/composables/push-notifications';
import { toast } from '@/composables/toast';
import { db } from '@/db';
import { getEnv } from '@/env/env';
import { analytics } from '@/firebase';
import i18n from '@/i18n';
import Router from '@/router/index';
import { GetItemPayload, RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { wait } from '@/utils/wait';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { alertController } from '@ionic/vue';
import { setUser } from '@sentry/vue';
import { setUserProperties } from 'firebase/analytics';
import Cookies from 'js-cookie';
import { filter, mapKeys, merge, pick, snakeCase } from 'lodash';
import { RouteLocationNormalized } from 'vue-router';
import { ActionContext, Module } from 'vuex';

export interface UserState {
    me: User | null;
    accessToken: string | null;
    refreshToken: string | null;
    impersonateToken: string | null;
    user?: User | null;
    users?: Users | null;
    userOptions?: Users | null;
    askerUserOptions?: Users | null;
    assigneeUserOptions?: Users | null;
    activeTimezone?: string;
}

export const user: Module<UserState, RootState> = merge(getBaseStore('user'), {
    namespaced: true,
    state: (): UserState => ({
        me: null,
        accessToken: null,
        refreshToken: null,
        impersonateToken: null,
        activeTimezone: null,
    }),
    mutations: {
        updateMe: (state: UserState, user: User | null) => {
            state.me = user;
        },
        updateAccessToken: (state: UserState, token: string | null) => {
            state.accessToken = token;
        },
        updateRefreshToken: (state: UserState, token: string | null) => {
            state.refreshToken = token;
        },
        updateImpersonateToken: (state: UserState, token: string | null) => {
            state.impersonateToken = token;
        },
        updateTimezone: (state: UserState, timezone: string) => {
            state.activeTimezone = timezone;
        },
        updateAskerUserOptions: (state: UserState, users: Users) => {
            state.askerUserOptions = users;
        },
        updateAssigneeUserOptions: (state: UserState, users: Users) => {
            state.assigneeUserOptions = users;
        },
    },
    actions: {
        async goLoginWs(
            { dispatch }: ActionContext<UserState, RootState>,
            data: {
                email: string;
                password: string;
                remember: boolean;
            },
        ) {
            await dispatch('getAccessToken', data);
            await dispatch('getMe');
        },
        async getAccessToken(
            { commit }: ActionContext<UserState, RootState>,
            data: { email: string; password: string; remember: boolean },
        ) {
            const { token, refresh_token }: { token: string; refresh_token: string } = await Axios.request({
                method: 'post',
                url: '/security/authentication_token',
                data,
            });
            commit('updateAccessToken', token);
            await storageSet('access_token', token);
            if (data?.remember) {
                commit('updateRefreshToken', refresh_token);
                await storageSet('refresh_token', refresh_token);
            }
        },
        async getRefreshToken({ state, commit, dispatch }: ActionContext<UserState, RootState>, tryCount) {
            await storageRemove('impersonate_token');
            commit('updateImpersonateToken', null);
            const activeRefreshToken = state.refreshToken ?? (await storageGet('refresh_token'));
            if (!activeRefreshToken) {
                await dispatch('logOut', {});
                return false;
            }
            try {
                const { token, refresh_token }: { token: string; refresh_token: string } = await Axios.request({
                    method: 'post',
                    url: '/security/token/refresh',
                    data: {
                        refresh_token: activeRefreshToken,
                    },
                });
                commit('updateAccessToken', token);
                commit('updateRefreshToken', refresh_token);
                await storageSet('access_token', token);
                await storageSet('refresh_token', refresh_token);
                return true;
            } catch (e) {
                if (tryCount === 1) await dispatch('logOut', {});
                return false;
            }
        },
        async getMe({ commit, dispatch }: ActionContext<UserState, RootState>) {
            const user = await Axios.request({
                method: 'get',
                url: '/api/private/users/me',
            });
            commit('updateMe', user);
            user['view_width'] = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            const userData = pick(user, [
                'area',
                'email',
                'first_name',
                'last_name',
                'level',
                'account_type',
                'id',
                'enabled',
                'view_width',
            ]);
            setUser(userData);
            setUserProperties(analytics, userData);
            dispatch('checkPushNotifications');
            return user;
        },
        async checkPushNotifications({ state }: ActionContext<UserState, RootState>) {
            if (!state.me || state.impersonateToken || state.me?.areas?.length === 0) return;
            // If web browser and Notification API is not available, we don't ask for push notifications
            if (!Capacitor.isNativePlatform() && typeof Notification === 'undefined') {
                console.error('Notification API not available');
                return;
            }
            const asked = await storageGet('push_notifications_asked');
            if (!asked) {
                showNotificationModal();
                return;
            }
            updateNotificationPermission();
        },
        async addDeviceToken({ state }: ActionContext<UserState, RootState>, { token }: { token: string }) {
            // console.log('Send Firebase Device Token : ', token);
            let infos: object = await Device.getInfo();
            infos = mapKeys(infos, (value, key) => snakeCase(key));
            try {
                await Axios.request({
                    method: 'post',
                    url: '/api/private/devices',
                    data: {
                        user: state.me?.['@id'],
                        token,
                        type: Capacitor.getPlatform(),
                        ...infos,
                    },
                });
            } catch (e: any) {
                if (e?.response?.status === 422) {
                    // console.log('Firebase Push Notification Token already in database');
                    return;
                } else {
                    throw e;
                }
            }
        },
        async userGuard({ state, commit, dispatch }: ActionContext<UserState, RootState>) {
            if (!state.me) {
                const accessToken = await storageGet('access_token');
                const impersonateToken = await storageGet('impersonate_token');
                if (accessToken) {
                    loader.start();
                    commit('updateAccessToken', accessToken);
                    if (impersonateToken) commit('updateImpersonateToken', impersonateToken);
                    try {
                        await dispatch('getMe');
                        loader.finish();
                        return true;
                    } catch (e) {
                        commit('updateAccessToken', null);
                        commit('updateRefreshToken', null);
                        commit('updateImpersonateToken', null);
                        await storageRemove('access_token');
                        await storageRemove('refresh_token');
                        await storageRemove('impersonate_token');
                        toast({
                            text: e.customErrorMsg ?? e.toString(),
                            type: 'error',
                        });
                        loader.finish();
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return true;
            }
        },
        async logOut(
            { commit }: ActionContext<UserState, RootState>,
            {
                showLogoutScreen = false,
            }: {
                showLogoutScreen: boolean;
            },
        ) {
            localStorage.removeItem('apenday_active_area');
            if (showLogoutScreen) {
                await Router.push({
                    name: 'logout',
                });
                await wait(1000); // Wait for the logout screen to be displayed
            }
            await storageRemove('access_token');
            await storageRemove('refresh_token');
            await storageRemove('impersonate_token');
            commit('updateAccessToken', null);
            commit('updateRefreshToken', null);
            commit('updateImpersonateToken', null);
            commit('updateMe', null);
            flushAllInteractions();
            await Router.push({
                name: 'login',
            });
        },
        async createUser({ commit, state }: ActionContext<UserState, RootState>, { routeLocation }: GetItemPayload) {
            commit('updateUser', {
                first_name: '',
                last_name: '',
                areas: [],
                account_type: routeLocation?.meta?.accountType,
            });

            return state.user;
        },
        async getExternalAuthUrl(context: ActionContext<UserState, RootState>, service: string) {
            try {
                const { auth_url: url }: { auth_url } = await Axios.request({
                    method: 'get',
                    url: '/security/external-auth-url/' + service,
                });
                if (Capacitor.isNativePlatform()) {
                    await Browser.open({ url });
                } else {
                    window.location.replace(url);
                }
                return Promise.resolve();
            } catch (e: any) {
                toast({
                    text: e.customErrorMsg ?? e.toString(),
                    type: 'error',
                });
                return Promise.reject();
            }
        },
        async externalLogin({ commit }: ActionContext<UserState, RootState>, to: RouteLocationNormalized) {
            if (to.query['code']) {
                try {
                    const { token, refresh_token }: { token: string; refresh_token: string } = await Axios.request({
                        method: 'get',
                        url: '/security/external-auth/' + to.meta['provider'],
                        params: {
                            code: to.query['code'],
                        },
                    });
                    commit('updateAccessToken', token);
                    commit('updateRefreshToken', refresh_token);
                    await storageSet('access_token', token);
                    await storageSet('refresh_token', refresh_token);
                    return true;
                } catch (e: any) {
                    const alert = await alertController.create({
                        header: i18n.global.t('alerts.external-auth.error.header'),
                        message: i18n.global.t('alerts.external-auth.error.message'),
                        cssClass: 'wysiwyg-alert',
                        buttons: [
                            {
                                text: i18n.global.t('alerts.actions.understood'),
                                role: 'confirm',
                                cssClass: 'confirm',
                            },
                        ],
                    });

                    await alert.present();
                    return false;
                }
            } else {
                return false;
            }
        },
        async impersonate({ commit, dispatch }: ActionContext<UserState, RootState>, { user }: { user: User }) {
            localStorage.removeItem('apenday_active_area');
            const { impersonate_token }: { impersonate_token: string } = await Axios.request({
                method: 'get',
                url: `/api/private/users/${user['id']}/impersonate`,
            });
            commit('updateImpersonateToken', impersonate_token);
            await storageSet('impersonate_token', impersonate_token);
            await dispatch('getMe');
            if (getEnv().features.notificationsCenter) await fetchingNotifications();
        },
        async killImpersonate({ commit, dispatch }: ActionContext<UserState, RootState>) {
            localStorage.removeItem('apenday_active_area');
            commit('updateImpersonateToken', null);
            await storageRemove('impersonate_token');
            await dispatch('getMe');
            if (getEnv().features.notificationsCenter) await fetchingNotifications();
        },
    },
    getters: {
        isAuthenticated(state: UserState) {
            return state.accessToken && state.me;
        },
        timezone(state: UserState): string {
            return state.activeTimezone ?? state.me?.areas?.[0]?.default_timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
        },
        areaWording(state: UserState) {
            return (
                state.me?.areas?.[0] ?? {
                    label_a_consultant: i18n.global.t('area.wording.label_a_consultant'),
                    label_agencies: i18n.global.t('area.wording.label_agencies'),
                    label_agency: i18n.global.t('area.wording.label_agency'),
                    label_an_agency: i18n.global.t('area.wording.label_an_agency'),
                    label_consultant: i18n.global.t('area.wording.label_consultant'),
                    label_consultants: i18n.global.t('area.wording.label_consultants'),
                    label_of_agencies: i18n.global.t('area.wording.label_of_agencies'),
                    label_of_agency: i18n.global.t('area.wording.label_of_agency'),
                    label_of_consultant: i18n.global.t('area.wording.label_of_consultant'),
                    label_of_consultants: i18n.global.t('area.wording.label_of_consultants'),
                    label_some_agencies: i18n.global.t('area.wording.label_some_agencies'),
                    label_some_consultants: i18n.global.t('area.wording.label_some_consultants'),
                    label_the_agencies: i18n.global.t('area.wording.label_the_agencies'),
                    label_the_agency: i18n.global.t('area.wording.label_the_agency'),
                    label_the_consultant: i18n.global.t('area.wording.label_the_consultant'),
                    label_the_consultants: i18n.global.t('area.wording.label_the_consultants'),
                    label_meeting_type_other: i18n.global.t('area.wording.label_meeting_type_other'),
                }
            );
        },
        isGranted(state: UserState): IsGranted {
            return {
                admin: state.me?.level === 'admin',
                manager: state.me?.level === 'admin' || state.me?.level === 'manager',
                group_manager: state.me?.level === 'admin' || state.me?.level === 'manager' || state.me?.level === 'group_manager',
                consultant:
                    state.me?.level === 'admin' ||
                    state.me?.level === 'manager' ||
                    state.me?.level === 'group_manager' ||
                    state.me?.level === 'consultant',
            };
        },
        isMultiAreas(state: UserState): boolean {
            return state.me?.areas?.length > 1;
        },
        hasMultiBookAreas(state: UserState): boolean {
            return filter(state.me?.areas, (o) => o['book_enabled']).length > 1;
        },
        hasMultiLiveAreas(state: UserState): boolean {
            return filter(state.me?.areas, (o) => o['live_enabled']).length > 1;
        },
        hasMultiAssistAreas(state: UserState): boolean {
            return filter(state.me?.areas, (o) => o['assist_enabled']).length > 1;
        },
        hasApendayBookEnabled(state: UserState): boolean {
            return !!filter(state.me?.areas, (o) => o['book_enabled']).length;
        },
        hasApendayLiveEnabled(state: UserState): boolean {
            return !!filter(state.me?.areas, (o) => o['live_enabled']).length;
        },
        hasApendayAssistEnabled(state: UserState): boolean {
            return !!filter(state.me?.areas, (o) => o['assist_enabled']).length;
        },
        allowedAvailabilityEdition(state: UserState, getters) {
            const allowedInAtLeastOne = filter(state.me?.areas, (o) => o['allowed_group_manager_availability_edition']).length;
            return (state.me?.level === 'group_manager' && !!allowedInAtLeastOne) || getters['isGranted']['manager'];
        },
        allowedMeetingEdition(state: UserState, getters): boolean {
            const allowedInAtLeastOne = filter(state.me?.areas, (o) => o['allowed_group_manager_meeting_edition']).length;
            return (state.me?.level === 'group_manager' && !!allowedInAtLeastOne) || getters['isGranted']['manager'];
        },
        allowedHubEdition(state: UserState, getters): boolean {
            const allowedInAtLeastOne = filter(state.me?.areas, (o) => o['allowed_group_manager_hub_edition']).length;
            return (state.me?.level === 'group_manager' && !!allowedInAtLeastOne) || getters['isGranted']['manager'];
        },
        allowedUserEdition(state: UserState, getters): boolean {
            const allowedInAtLeastOne = filter(state.me?.areas, (o) => o['allowed_group_manager_user_edition']).length;
            return (state.me?.level === 'group_manager' && !!allowedInAtLeastOne) || getters['isGranted']['manager'];
        },
        allowedStatsOfTeamRead(state: UserState, getters): boolean {
            const allowedInAtLeastOne = filter(state.me?.areas, (o) => o['allowed_group_manager_stats_of_team_read']).length;
            return (state.me?.level === 'group_manager' && !!allowedInAtLeastOne) || getters['isGranted']['manager'];
        },
        availableSolutions(state: UserState): Solution[] {
            const solutions = [];
            if (state.me?.areas?.length) {
                state.me.areas.forEach((area) => {
                    area.available_solutions.forEach((solution) => {
                        solutions.push(solution);
                    });
                });
            }

            return [...new Set(solutions)];
        },
        askerUserOptions(state: UserState): Users {
            return state.askerUserOptions ? state.askerUserOptions['hydra:member'] : [];
        },
        assigneeUserOptions(state: UserState): Users {
            return state.assigneeUserOptions ? state.assigneeUserOptions['hydra:member'] : [];
        },
    },
});

export const storageSet = async (key: string, value: any) => {
    if (Capacitor.isNativePlatform()) {
        await db.constants.put(value, key);
    } else {
        Cookies.set(key, value, { secure: true, expires: 14 });
    }
};

export const storageGet = async (key: string) => {
    if (Capacitor.isNativePlatform()) {
        return await db.constants.get(key);
    } else {
        return Cookies.get(key);
    }
};

export const storageRemove = async (key: string) => {
    if (Capacitor.isNativePlatform()) {
        await db.constants.delete(key);
    } else {
        Cookies.remove(key);
    }
};
