import mitt from 'mitt';

type Option = string | object | number;
export type ModelValue = Option[] | Option | null;

type Events = {
    backdropTap: null;
    newRefreshTokenOk: null;
    newRefreshTokenFail: null;
    resize: null;
    shareMedia: { recordId?: string };
    clearInputSelectField: ModelValue;
};

export const emitter = mitt<Events>(); // inferred as Emitter<Events>
